// Chakra imports
import {
  Box, Flex
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from 'axios';

// Custom components
import Banner from "./items/Banner";
import GeneralInformation from "./items/General";

// Assets
import banner from "assets/img/auth/banner.png";
import logo from "assets/img/dashboards/Logo Dark Blue-01-01.jpg";
import Requests from "./items/Request";
import UploadImage from "./items/Upload";
import VacationsRequestModal from "./items/forms/vacationsModal";
import PermissionRequestModal from "./items/forms/permissionModal";
import ChangeScheduleRequestModal from "./items/forms/changeScheduleModal";
import UserRequests from "./items/Requests";

export default function OverviewProfile() {

  const [user, setUser] = useState("");
  const [typeUserName, setTypeUserName] = useState('');
  const [bonoEnable, setBonoEnable] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [vacationsDay, setVacationsDay] = useState("");
  const [vacationLeft, setVacationsLeft] = useState("");
  const [vacationUse, setVacationUse] = useState("");
  const [permissionAvaiable, setPermissionAvaiable] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get('/user')
      const user = response.data.user;
      const receivedTypeUserName = response.data.type_user_name;
      const bono = response.data.bonoEnable;
      const vacationsDays = response.data.vacationsDays;
      const vacationLeft = response.data.vacationsLeft;
      const vacationUse = response.data.vacationsUse;
      const permissionAvaiable = response.data.permissionAvaiable;

      setUser(user);
      setTypeUserName(receivedTypeUserName);
      setBonoEnable(bono);
      setVacationsDay(vacationsDays);
      setVacationsLeft(vacationLeft);
      setVacationUse(vacationUse);
      setPermissionAvaiable(permissionAvaiable);

    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  };


  const fetchProfilePicture = async () => {
    try {
      const response = await axios.get('/users/profilePicture', {
        responseType: 'blob',
      });
      const imageBlob = URL.createObjectURL(response.data);
      setImageUrl(imageBlob);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchProfilePicture();
  }, []);

  const dateStart = user.profile && user.profile.first_day ? user.profile.first_day : '---';
  const dateStartCalls = user.profile && user.profile.date_start_calls ? user.profile.date_start_calls : '---';

  const profilePicture = imageUrl ? imageUrl : logo;

  const [modalUpdateImage, setModalUpdateImage] = useState(false);
  const [vacationModal, setVacationModal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [changeSchedule, setChangeScheduleModal] = useState(false);

  const openUpdateImage = () => {
    setModalUpdateImage(true);
  };

  const openModalVacations = () => {
    setVacationModal(true);
  }
  const closeModalVacations = () => {
    setVacationModal(false);
  }

  const openModalPermissions = () => {
    setPermissionModal(true);
  }
  const closeModalPermissions = () => {
    setPermissionModal(false);
  }
  const openModalChangeSchedule = () => {
    setChangeScheduleModal(true);
  }
  const closeModalChangeSchedule = () => {
    setChangeScheduleModal(false);
  }


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} mt={'1%'} >
      <Flex >
        <Flex direction={'column'} width={'100%'} height={'100%'}>
          <Banner
            gridArea='1 / 1 / 2 / 2'
            banner={banner}
            avatar={user.path_confirm === 2 ? profilePicture : logo}
            name={user.name + " " + user.last_name}
            job={typeUserName}
            dateStart={dateStart}
            dateStartCalls={dateStartCalls}
            mt='3%'
            user={user}
            bonoEnable={bonoEnable}
            updateImage={openUpdateImage}
            vacationsDay={vacationsDay}
            vacationLeft={vacationLeft}
            vacationUse={vacationUse}
            permissionAvaiable={permissionAvaiable}
          />
        </Flex>

        <Flex direction={'column'} width={'100%'} height={'100%'}>
          <GeneralInformation
            gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
            minH='365px'
            pe='20px'
            user={user}
          />

        </Flex>

        <Flex direction={'column'} width={'100%'} height={'100%'} >
          <Requests
            openModalVacations={openModalVacations}
            openModalPermissions={openModalPermissions}
            openModalChangeSchedule={openModalChangeSchedule}
            user={user}
          />
          <Flex mt={'5%'}>
            <UserRequests />
          </Flex>
        </Flex>
      </Flex>
      {modalUpdateImage && (
        <UploadImage
          isOpen={openUpdateImage}
          onClose={() => setModalUpdateImage(false)}
          fetchProfilePicture={fetchProfilePicture}
          imageUrl={user.path_confirm === 2 ? profilePicture : logo}
          user={user}
        />
      )}
      {vacationModal && (
        <VacationsRequestModal isOpen={openModalVacations} onClose={closeModalVacations} />
      )}
      {permissionModal && (
        <PermissionRequestModal isOpen={openModalPermissions} onClose={closeModalPermissions} />
      )}
      {changeSchedule && (
        <ChangeScheduleRequestModal isOpen={openModalChangeSchedule} onClose={closeModalChangeSchedule} />
      )}

    </Box>
  );
}

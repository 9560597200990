import axios from 'axios';
import React from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody,
    Button, Flex, Image
} from '@chakra-ui/react';
import survey from 'assets/img/home/survey.jpeg';

function ModalSurvey({ isOpen, onClose }) {

    const startSurvey = async () => {
        try {
            const response = await axios.post('/confirmSurvey');

            if (response.status === 200) {
                window.open('https://form.jotform.com/DedicatedPeopleHiring/employee-satisfaction-survey', '_blank');
                onClose();
            }

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} closeOnOverlayClick={false} closeOnEsc={false} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody marginTop={'4%'} marginBottom={'4%'}>
                    <Flex display={'flex'} justifyContent={'center'} position={'relative'}>
                        <Image src={survey} />
                        <Button
                            position={'absolute'}
                            top={'90%'}
                            left={'15%'}
                            transform={'translate(-50%, -50%)'}
                            size={'md'}
                            color={'white'}
                            bg={'#112f4f'}
                            borderRadius={'5px'}
                            _hover={{ background: '#808080', color: 'white' }}
                            onClick={startSurvey}
                        >
                            Start now
                        </Button>
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalSurvey;

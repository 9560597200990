import React, { useEffect } from 'react';
import {
  Table, Thead, Tbody, Tr, Th, Td,
  Button, ButtonGroup,
  Text, Flex, Box, Badge,
  Menu, MenuItem, MenuList, MenuButton
} from '@chakra-ui/react'
import { SettingsIcon, CheckIcon } from '@chakra-ui/icons'
import { BsCheckSquareFill } from "react-icons/bs";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { useUserData } from 'interceptors/userAuth';
import Filters from './filterPermissions'

function PermissionsList({ optionsUsers, userSearch, total, currentPage, permissions, handleSelectChange, setCurrentPage, customStyles, totalPages, filterOptions, resetKey, clearFilters, totalSchedule, handleStatus, exportPermissions }) {

  const { user, fetchData } = useUserData();

  useEffect(() => {
    fetchData();
  }, []);

  const getColorStatus = (option) => {
    const colorMap = {
      1: '#fabb1c',
      2: '#a2bd34',
      3: '#fc7466'
    };
    return colorMap[option] || '#8a6dce';
  };

  return (
    <>
      <Flex
        direction='column'
        w='100%'
      >
        <Filters
          optionsUsers={optionsUsers}
          userSearch={userSearch}
          handleSelectChange={handleSelectChange}
          setCurrentPage={setCurrentPage}
          customStyles={customStyles}
          filterOptions={filterOptions}
          resetKey={resetKey}
          clearFilters={clearFilters}
          user={user}
          exportPermissions={exportPermissions}
        />

        <Table size={'sm'} mt={"2%"}>
          <Thead bg={"white"}>
            <Tr>
              <Th textAlign={'center'}>ID</Th>
              <Th textAlign={'center'}>Name</Th>
              <Th textAlign={'center'}>HR Status</Th>
              <Th textAlign={'center'}>Client/Managment Status</Th>
              <Th textAlign={'center'}>Type Of Request</Th>
              <Th textAlign={'center'}>Start Date</Th>
              <Th textAlign={'center'}>End Date</Th>
              <Th textAlign={'center'}>Reason Of Request</Th>
              <Th textAlign={'center'}>Coverage</Th>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(user.type_user_id) ? (
                <Th textAlign={'center'}>Actions</Th>
              ) : null}
            </Tr>
          </Thead>
          <Tbody>
            {permissions && permissions.length > 0 ? (
              permissions.map((permissions, index) => (
                <Tr key={permissions.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {permissions.user.profile.mat_dp}
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {permissions.user.name.split(' ')[0]} {permissions.user.last_name.split(' ')[0]}
                  </Td>
                  <Td textAlign={'center'} fontWeight={"bold"}>
                    <Badge
                      color='white'
                      bg={getColorStatus(permissions.status_HR)}
                      borderRadius={'5px'}
                      width={'100px'}
                      fontSize={'10px'}
                      whiteSpace={'pre-wrap'}
                    >

                      {(() => {
                        switch (permissions.status_HR) {
                          case 1:
                            return "Pending";
                          case 2:
                            return "Approved";
                          case 3:
                            return "Rejected";
                          default:
                            return "---";
                        }
                      })()}
                    </Badge>

                  </Td>
                  <Td textAlign={'center'} fontWeight={"bold"}>
                    <Badge
                      color='white'
                      bg={getColorStatus(permissions.status_Client)}
                      borderRadius={'5px'}
                      width={'100px'}
                      fontSize={'10px'}
                      whiteSpace={'pre-wrap'}
                    >

                      {(() => {
                        switch (permissions.status_Client) {
                          case 1:
                            return "Pending";
                          case 2:
                            return "Approved";
                          case 3:
                            return "Rejected";
                          default:
                            return "---";
                        }
                      })()}
                    </Badge>

                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {permissions.type_request}
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {permissions.start_date}
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {permissions.end_date}
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {permissions.reason_request ? permissions.reason_request : "---"}
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {permissions.coverage}
                  </Td>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(user.type_user_id) ? (
                    <Td textAlign={'center'}>
                      <Menu>
                        <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                          <SettingsIcon pb={"2px"} />
                        </MenuButton>
                        <MenuList>
                          <MenuItem isDisabled>
                            <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                          </MenuItem>
                          <MenuItem
                            display={"flex"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                            alignItems="center">
                            <Button
                              borderRadius={"5px"}
                              bg={"#f2f2f2"}
                              color={"#1c2147"}
                              _hover={{ background: "#7080fc", color: 'white' }}
                              onClick={() => handleStatus(permissions, 'status_HR')}
                              width={"100%"}
                              justifyContent="space-between"
                              alignItems="center">
                              <Text flex={1} textAlign="center">
                                Change Status HR
                              </Text>
                              <CheckIcon ml={2} verticalAlign="middle" />
                            </Button>
                          </MenuItem>
                          {[1, 2, 3, 4, 5, 6, 7].includes(user.type_user_id) ? (
                            <MenuItem
                              display={"flex"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                              alignItems="center">
                              <Button
                                borderRadius={"5px"}
                                bg={"#f2f2f2"}
                                color='#1c2147'
                                _hover={{ background: "#fabb1c", color: 'white' }}
                                onClick={() => handleStatus(permissions, 'status_Client')}
                                width={"100%"}
                                justifyContent="space-between"
                                alignItems="center">
                                <Text flex={1} textAlign="center">
                                  Change Status Client / Managment
                                </Text>
                                <CheckIcon ml={2} verticalAlign="middle" />
                              </Button>
                            </MenuItem>
                          ) : null}
                        </MenuList>
                      </Menu>
                    </Td>
                  ) : null}
                </Tr>
              ))
            ) : (
              <Tr>
                <Th colSpan={'12'} >
                  <Text
                    color='#1c2147'
                    fontSize={"25px"}
                    textAlign={"center"}
                    paddingTop={"15px"}
                    paddingBottom={"15px"}
                    bg={"#f2f2f2"}
                    textTransform={'none'}
                    fontFamily={'DM Sans'}
                    letterSpacing="-1px"
                  >
                    No data
                  </Text>
                </Th>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex >
      <Box width={"100%"} display={"flex"} mt={"30px"}>
        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
          {total === 0 ? (
            <>
              <Flex bg={'#f2f2f2'} width={'250px'} display={'flex'} justifyContent={'center'}>
                <Box mt={'5px'} mr={2}>
                  <BsCheckSquareFill color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                  No Permissions
                </Text>


              </Flex>
            </>
          ) : (
            <>
              <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}>
                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                  {total}
                </Text>
                <Box mt={'5px'} mr={1}>
                  <BsCheckSquareFill color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={3}>
                  {total === 1 ? 'Permission' : 'Permissions'}
                </Text>
                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                  {totalSchedule}
                </Text>

              </Flex>
            </>
          )}
        </Flex>
        <ButtonGroup isAttached variant="outline" ml={"26%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
          <Button
            borderRadius={'0px'}
            onClick={() => setCurrentPage(currentPage - 1)}
            isDisabled={currentPage === 1}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowLeftShort fontSize="20px" />
          </Button>
          <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
          <Button
            borderRadius={'0px'} ml={4}
            onClick={() => setCurrentPage(currentPage + 1)}
            isDisabled={currentPage === totalPages}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowRightShort fontSize="20px" />
          </Button>
        </ButtonGroup>
      </Box>
    </>

  );
}
export default PermissionsList; 
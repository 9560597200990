import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
  Text, Button, useToast, Flex, Box
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'
import { FaUser } from "react-icons/fa";
import moment from 'moment';
import Swal from 'sweetalert2'

//Sections
import SchedulesSections from './itemsUpdate/schedule';
import BreaksDetailsSection from './itemsUpdate/breaks_details';
import ClockInSection from './itemsUpdate/clockTime';
import ShiftSection from './itemsUpdate/shift';
import NotesSection from './itemsUpdate/notes';
import SchedulesIncidentsSection from './itemsUpdate/schedules_incidents';


const ScheduleUpdateModal = ({ isOpen, onClose, schedule, user, data, setFlashing, client, handleNotes, dataUpdate, userAuth }) => {

  const toast = useToast();
  const [errorMsg, setErrorMsg] = useState("");
  const [resetKey, setResetKey] = useState(0);
  const [selectedClient, setSelectedClient] = useState(schedule?.id_client || '');
  const [selectedCoverage, setSelectedCoverage] = useState(schedule?.id_operator_coverage || '');
  const [notes, setNotes] = useState([]);

  const [formData, setFormData] = useState({
    time_start: schedule?.time_start || '',
    hours: schedule?.hours !== null && schedule?.hours !== undefined ? schedule.hours : 0,
    minutes: schedule?.minutes !== null && schedule?.minutes !== undefined ? schedule.minutes : 0,
    time_end: schedule?.time_end || '',
    type_daily: schedule?.type_daily || '',
    option: schedule?.option || '',
    status: schedule?.status || '',
    id_client: schedule?.id_client || '',
    id_operator_coverage: schedule?.id_operator_coverage || '',
  });

  const filterOptions = (candidate, input) => {
    const words = input.toLowerCase().split(' ');
    return words.every(word =>
      candidate.label.toLowerCase().includes(word)
    );
  };

  const optionClients = client.map(client => ({
    value: client.id,
    label: client.name,
  }));

  const optionsUsers = user.map(user => ({
    value: user.id,
    label: `${user.name} ${user.last_name}`,
  }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#f2f2f2',
      borderRadius: '5px',
      borderColor: 'white',
      textColor: 'red',
      color: 'red',
      fontSize: '16px',
      height: '40px',
      width: '260px',
      '&:hover': {
        borderColor: '#808080',
      },
      '&:focus-visible': {
        outline: 'none',
        boxShadow: 'none'
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      width: '260px'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
      backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
    }),
  };


  const [formDataShift, setFormDataShift] = useState({
    duration: schedule.time?.duration || '',
  });

  const [formDataBreaks, setFormDataBreaks] = useState([]);

  const handleChangeShift = (e) => {
    const { name, value } = e.target;

    setFormDataShift((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBreakInputChange = (index, value) => {
    const updatedFormDataBreaks = [...formDataBreaks];
    updatedFormDataBreaks[index] = { ...updatedFormDataBreaks[index], duration: value };
    setFormDataBreaks(updatedFormDataBreaks);
  };

  const handleUpdateShift = async () => {

    const formSubmitData = {
      duration: formDataShift.duration,
    };

    try {
      const response = await axios.put(`/reports/durationShift/${schedule.id}`, formSubmitData);
      if (response.status === 200) {
        toast({
          title: "Duration Shift Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        data(schedule);
        onClose();
      }
    } catch (error) {
      toast({
        title: "Error Updating Duration",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error('Error Updating Duration:', error);
    }
  };


  const handleUpdateBreaks = async (breakItem, duration) => {
    try {
      const response = await axios.put(`/reports/durationBreak/${breakItem.id}`, {
        duration: duration,
      });

      if (response.status === 200) {
        toast({
          title: "Duration Shift Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        data(schedule);
        onClose();
      }
    } catch (error) {
      toast({
        title: "Error Updating Duration",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error('Error Updating Duration:', error);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'hours') {
      const isValidHours = /^\d+$/.test(value) && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 23;

      if (isValidHours || value === '') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
    else if (name === 'minutes') {
      const isValidMinutes = /^\d+$/.test(value) && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 59;

      if (isValidMinutes || value === '') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption.value);
  };

  const handleCoverageChange = (selectedOption) => {
    setSelectedCoverage(selectedOption.value);
  };

  const handleUpdate = async () => {

    const formSubmitData = {
      time_start: formData.time_start,
      minutesN: formData.minutes,
      hoursN: formData.hours,
      time_end: formData.time_end,
      status: formData.status,
      type_daily: formData.type_daily,
      id_operator_coverage: selectedCoverage,
      id_client: selectedClient,
      option: formData.option
    };

    try {
      const response = await axios.post(`/schedule/update/${schedule.id}`, formSubmitData);
      if (response.data.msg) {
        setErrorMsg(response.data.msg);
      } else {
        toast({
          title: "Schedule Update",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        dataUpdate();
        onClose();
        setFlashing(formSubmitData);
        data(schedule);
      }
    } catch (error) {
      toast({
        title: "Error Updating Schedule",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error('Error Updating User Type:', error);
    }
  };

  useEffect(() => {
    calculateTimeEnd();
  }, [formData.time_start, formData.hours, formData.minutes]);


  const calculateTimeEnd = () => {
    const { time_start, hours, minutes } = formData;

    if (time_start !== undefined && hours !== undefined && minutes !== undefined) {
      const startTime = moment(`${time_start}`, 'HH:mm:ss');

      if (hours > 0) {
        startTime.add(hours, 'hours');
      }

      if (minutes > 0) {
        startTime.add(minutes, 'minutes');
      }
      const formattedTimeEnd = startTime.format('HH:mm:ss');
      setFormData((prevData) => ({
        ...prevData,
        time_end: formattedTimeEnd,
      }));
    }
  };

  const handleGenerateLunch = async () => {

    try {
      const response = await axios.post(`/generateLunch/${schedule.id}`);
      toast({
        title: "Lunch generated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
      data(schedule);
    } catch (error) {
      toast({
        title: "Error generating lunch",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error('Error Updating User Type:', error);
    }
  };

  const handleGenerateBreaks = async () => {

    try {
      const response = await axios.post(`/generateBreak/${schedule.id}`);
      toast({
        title: "Break generated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
      data(schedule);
    } catch (error) {
      toast({
        title: "Error generating break",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error('Error Updating User Type:', error);
    }
  };


  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      id_client: selectedClient,
      id_operator_coverage: selectedCoverage
    }));
  }, [selectedClient, selectedCoverage]);


  const getFormattedTime = (dateTimeString) => {
    if (!dateTimeString) {
      return '';
    }

    const date = moment(dateTimeString, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
    return date;
  };


  const [formDataShift2, setFormDataShift2] = useState({
    date_start: getFormattedTime(schedule.time?.date_start),
    date_end: getFormattedTime(schedule.time?.date_end),
    option: schedule?.option || '',
  });


  const handleChangeShift2 = (e) => {
    const { name, value } = e.target;

    setFormDataShift2((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdateShift2 = async () => {

    const formSubmitData = {
      date_start: formDataShift2.date_start,
      option: formDataShift2.option,
      date_end: formDataShift2.date_end
    };

    try {
      const response = await axios.put(`/reports/editShift/${schedule.id}`, formSubmitData);
      console.log('Response from server:', response.data);
      if (response.status === 200) {
        toast({
          title: "Duration Shift Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        data(schedule);
        onClose();
      }
    } catch (error) {
      toast({
        title: "Error Updating Duration",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error('Error Updating Duration:', error);
    }
  };

  const fetchData = async (schedules) => {
    try {
      const response = await axios.get(`/notes/${schedule.id}`);
      setNotes(response.data);
    } catch (error) {
      console.error('Error fetching Notes:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []
  );

  const getColorSchemeOption = (option) => {
    const colorMap = {
      1: '#fcd677', //Late
      2: '#8a6dce', //Call out
      3: '#fca066', //Leave early
      4: '#ff9fb8', //No call, no show
      5: '#6bc2ed', //Cell phone use
      6: '#fc7466', //Camera off
      7: '#bf957e', //Other and clock in description
      8: '#a2bd34', //Permission with client 
      9: '#7080fc', //Disciplinary actions
      10: '#424ea8', //Vacations
      11: '#fcd677', //Late from lunch
      12: '#fcd677', //Late from break

    };
    return colorMap[option] || '#b3b3b3'; //default
  };

  const deleteNote = async (note) => {
    const confirmDelete = await Swal.fire({
      title: 'Do you want to delete this note?',
      text: 'The note will be deleted.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#b9ce67',
      cancelButtonColor: '#fc7466',
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      closeOnConfirm: true,
      closeOnCancel: false
    }).then((result) => {
      return result.isConfirmed
    });

    if (confirmDelete) {
      try {
        const response = await axios.delete(`/notes/delete/${note.id}`);
        toast({
          title: " Note deleted",
          status: "success",
          position: 'top',
          duration: 3000,
          isClosable: true,
        });
        fetchData();
      } catch (error) {
        console.error(error);
        toast({
          title: "Error deleting Note",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };


  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}
      closeOnOverlayClick={false}
      closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent background={"#f2f2f2"} width={"100%"} borderRadius={"20px"}>
        <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'2%'} fontWeight={'bold'}>
          <Flex direction={'column'} textAlign={"center"} width={'30%'} >
            <Flex
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              color={'#1c2147'}
              fontWeight={'bold'}
              bg={'white'}
              borderRadius={'20px'}
              mb={'1.5%'}
              height={'80px'}
            >
              <Box textAlign={"center"} >
                <FaUser size={'30px'} />
              </Box>
              <span style={{ marginLeft: '2%', marginRight: '3%' }}><span style={{ marginRight: '0%', color: '#1c2147' }}>{schedule.user.name} {schedule.user.last_name}</span></span>
            </Flex>
            <span style={{ color: '#7080fc' }}>{schedule.date}</span>
          </Flex>
        </ModalHeader>
        <ModalBody display={'flex'} justifyContent={'center'}  >
          <Flex
            flexDirection={"row"}
            bg={'white'}
            borderRadius={'20px'}
            justifyContent={'space-evenly'}
            mt={'2%'}
            width={'100%'}
            height={'100%'}
            shadow={"5px 5px 20px 0px"}
            color={"#b3b3b3"}
          >

            <SchedulesSections
              formData={formData}
              handleChange={handleChange}
              errorMsg={errorMsg}
              optionsUsers={optionsUsers}
              selectedCoverage={selectedCoverage}
              filterOptions={filterOptions}
              customStyles={customStyles}
              resetKey={resetKey}
              selectedClient={selectedClient}
              optionClients={optionClients}
              handleUpdate={handleUpdate}
              handleCoverageChange={handleCoverageChange}
              handleClientChange={handleClientChange}
            />

            <Flex mr={"1%"}  width={'100%'} maxWidth={'100%'} overflowX={'hidden'}>
              <Flex direction={'column'} alignItems={'center'} mt={'10%'} bg={'#f2f2f2'} mb={'1%'} height={'75%'} width={'350px'} borderRadius={'20px'}>
                <Flex display={'flex'} mt={'10%'} justifyContent={'space-around'}>
                  <Text justifyContent={"center"} color={'#1c2147'} fontWeight={'bold'} fontSize={'120%'} >
                    Clock In Time
                  </Text>
                </Flex>

                <ClockInSection
                  schedule={schedule}
                  formDataShift={formDataShift}
                  handleChangeShift={handleChangeShift}
                  handleUpdateShift={handleUpdateShift}
                />


                <Flex display={'flex'} mt={'15%'} width={'100%'} justifyContent={'center'} direction={'column'} maxWidth={'100%'} overflowX={'hidden'} >
                  <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'120%'} textAlign={'center'}>
                    Breaks details
                  </Text>
                  <Flex display={'flex'} justifyContent={'center'} direction={'column'} width={'100%'} maxWidth={'100%'} overflowX={'hidden'}>
                    <BreaksDetailsSection
                      schedule={schedule}
                      handleGenerateLunch={handleGenerateLunch}
                      handleGenerateBreaks={handleGenerateBreaks}
                      formDataBreaks={formDataBreaks}
                      handleBreakInputChange={handleBreakInputChange}
                      handleUpdateBreaks={handleUpdateBreaks}
                    />
                  </Flex>
                </Flex>


              </Flex>
            </Flex>

            {[1, 43, 45].includes(userAuth) ? (
              <SchedulesIncidentsSection
                schedule={schedule}
                onClose={onClose}
                data={data}
                toast={toast}
              />
            ) : null}
            
            <ShiftSection
              schedule={schedule}
              formDataShift2={formDataShift2}
              handleChangeShift2={handleChangeShift2}
              handleUpdateShift2={handleUpdateShift2}
            />

            <NotesSection
              handleNotes={handleNotes}
              schedule={schedule}
              notes={notes}
              getColorSchemeOption={getColorSchemeOption}
              deleteNote={deleteNote}
            />



          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="center" mb={'1%'} >
          <Button class="buttonCancel" onClick={onClose}>
            Close <CloseIcon ml={2} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal >
  );
};
export default ScheduleUpdateModal; 
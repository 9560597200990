import React from 'react';
import Select2 from 'react-select'
import {
    Button, Select, Input, Flex, Box,
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import { AiOutlineClear } from "react-icons/ai";

function WeeklySearch({ clientSearch, usersClients, optionsUsers, userSearch, handleSelectChange, setCurrentPage, customStyles, resetKey, filterOptions, optionClients, handleClientChange, dailySearch, setDailySearch, filterDate, setFilterDate, acceptSearch, setAcceptSearch, filterDateEnd, setFilterDateEnd, postSearch, setPostSearch, statusDSearch, setStatusDSearch, daySearch, setDaySearch, statusSearch, setStatusSearch, clearFilters }) {

    return (
        <>
            <Flex
                direction='row' justify={"space-evenly"}
                w='auto'
                bg="gray.200"
                p={2}
                borderRadius="16px"
            >
                <Flex flexDirection={"column"}>
                    <Flex align="center"   >
                        <Select2
                            options={clientSearch > 0 ? usersClients : optionsUsers}
                            value={clientSearch > 0 ? usersClients.find(option => option.value === userSearch) : optionsUsers.find(option => option.value === userSearch)}
                            onChange={(selectedOption) => {
                                handleSelectChange('user', selectedOption);
                                setCurrentPage(1);
                            }}
                            styles={customStyles}
                            placeholder="Select agent"
                            key={`userSelect-${resetKey}`}
                            isSearchable
                            filterOption={filterOptions}
                        />
                        <Select2
                            options={optionClients}
                            value={optionClients.find(option => option.value === clientSearch)}
                            onChange={(selectedOption) => {
                                handleClientChange(selectedOption);
                                setCurrentPage(1);
                            }}
                            styles={customStyles}
                            placeholder="Select client"
                            key={`clientSelect-${resetKey}`}
                        />
                        <Select
                            value={dailySearch}
                            width={'245px'}
                            borderColor={'#808080'}
                            ml={6}
                            fontSize={"sm"}
                            color={'#808080'}
                            placeholder='Work type'
                            onChange={(e) => {
                                setDailySearch(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            _hover={{
                                borderColor: "#808080",
                            }}
                        >
                            <option value="1">Regular shift</option>
                            <option value="2">Regular Training</option>
                            <option value="4">Regular Coverage</option>
                            <option value="5">Regular shadowing</option>
                            <option value="3">Additional hours</option>
                            <option value="7">Additional training</option>
                            <option value="8">Additional coverage</option>
                            <option value="9">Additional shadowing</option>
                            <option value="6">No shift</option>

                        </Select>
                        <Flex
                            border={'1px solid #808080'}
                            display={'flex'}
                            borderRadius={'4px'}
                            ml={6} mr={4}
                        >
                            <Input
                                width={'200px'}
                                border={'0px'}
                                type="date"
                                fontSize={"sm"}
                                value={filterDate}
                                onChange={(e) => {
                                    setFilterDate(e.target.value);
                                    setCurrentPage(1);
                                }}
                                _focusVisible={'none'}
                                color={'#808080'}
                            />
                            <Button bg={'none'} color={'#808080'} onClick={() => setFilterDate('')}>
                                <RepeatIcon />
                            </Button>
                        </Flex>
                        <Flex
                            border={'1px solid #808080'}
                            display={'flex'}
                            borderRadius={'4px'}
                            ml={2} mr={4}
                        >
                            <Input
                                width={'200px'}
                                border={'0px'}
                                type="date"
                                fontSize={"sm"}
                                value={filterDateEnd}
                                onChange={(e) => {
                                    setFilterDateEnd(e.target.value);
                                    setCurrentPage(1);
                                }}
                                _focusVisible={'none'}
                                color={'#808080'}
                            />
                            <Button bg={'none'} color={'#808080'} onClick={() => setFilterDateEnd('')}>
                                <RepeatIcon />
                            </Button>
                        </Flex>
                    </Flex>

                    <Flex justify={"space-evenly"} mt={"1.5%"}>
                        <Select
                            value={acceptSearch}
                            width={'252px'}
                            borderColor={'#808080'}
                            mr={-4}
                            fontSize={"sm"}
                            color={'#808080'}
                            onChange={(e) => {
                                setAcceptSearch(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            _hover={{
                                borderColor: "#808080",
                            }}
                        >
                            <option value="">Accept status</option>
                            <option value="0">Unaccept</option>
                            <option value="1">Accept</option>
                        </Select>
                        <Select
                            value={postSearch}
                            width={'249px'}
                            borderColor={'#808080'}
                            ml={6}
                            fontSize={"sm"}
                            color={'#808080'}
                            onChange={(e) => {
                                setPostSearch(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            _hover={{
                                borderColor: "#808080",
                            }}
                        >
                            <option value="">Publish status</option>
                            <option value="0">Unpublish</option>
                            <option value="1">Publish</option>
                        </Select>
                        <Select
                            value={statusSearch}
                            width={'245px'}
                            borderColor={'#808080'}
                            ml={"18px"}
                            fontSize={"sm"}
                            color={'#808080'}
                            onChange={(e) => {
                                setStatusSearch(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            _hover={{
                                borderColor: "#808080",
                            }}
                        >
                            <option value="">Status</option>
                            <option value="1">Normal</option>
                            <option value="12">Agent Overnight</option>
                            <option value="11">Surecall O/C</option>
                            <option value="2">Off</option>
                            <option value="3">Holiday</option>
                            <option value="15">Holiday Off</option>
                            <option value="4">Vacation</option>
                            <option value="5">Maternity Leave</option>
                            <option value="6">Permission Request</option>
                            <option value="7">Wedding Leave</option>
                            <option value="8">Medical Leave</option>
                            <option value="9">Paternity Leave</option>
                            <option value="10">Bereavement Leave</option>
                            <option value='13'>No longer with us</option>
                        </Select>
                        <Select
                            value={daySearch}
                            width={'250px'}
                            borderColor={'#808080'}
                            ml={4}
                            fontSize={"sm"}
                            color={'#808080'}
                            onChange={(e) => {
                                setDaySearch(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            _hover={{
                                borderColor: "#808080",
                            }}
                        >
                            <option value="">Day</option>
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                            <option value="0">Sunday</option>
                        </Select>
                        <Select
                            value={statusDSearch}
                            width={'250px'}
                            borderColor={'#808080'}
                            ml={4}
                            fontSize={"sm"}
                            color={'#808080'}
                            onChange={(e) => {
                                setStatusDSearch(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            _hover={{
                                borderColor: "#808080",
                            }}
                        >
                            <option value="">Select active schedule</option>
                            <option value="1">Active</option>
                            <option value="D">Deleted</option>
                        </Select>

                    </Flex>
                </Flex>
                <Box height={"max-content"} mt={"2%"} width={"max-content"}>
                    <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                        <AiOutlineClear size={'26px'} />
                    </Button>
                </Box>
            </Flex>
        </>
    );
}
export default WeeklySearch; 
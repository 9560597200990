import React from 'react';

//Icons
import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdHome, } from 'react-icons/md';
import { BsFillFilePersonFill } from "react-icons/bs";
import { FaRegCalendar } from "react-icons/fa6";
import { IoTicketSharp } from "react-icons/io5";
import { TbReportAnalytics } from "react-icons/tb";

//Templates
import DashboardsDefault from 'views/admin/dashboards/default';
import UsersSchedulesOverview from 'views/admin/main/schedules/weeklyschedule';
import UsersOverview from 'views/admin/main/users/newUser/items/userDashboard';
import Default from 'views/admin/main/others/messages';
import DefaultUser from 'views/admin/main/others/messages/indexUser';
import ClientsOverview from 'views/admin/main/clients';
import SettingsOverview from 'views/admin/main/settings';
import IncidentsOverview from 'views/admin/main/incidents';
import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';
import TypeUserOverView from 'views/admin/main/user_type';
import DailyOverview from 'views/admin/main/schedules/dailyschedule';
import ReportsOverview from 'views/admin/main/reports';
import MyScheduleOverview from 'views/admin/main/schedules/myschedule';
import ReportsTSOverview from 'views/admin/main/reports/indexTS';
import ReportsPROverview from 'views/admin/main/reports/indexPR';
import SchedulesHrsOverview from 'views/admin/main/schedules/scheduleHrs';
import ReportsTSEOverview from 'views/admin/main/reports/indexTSEndicott';
import RecordingsOverview from 'views/admin/main/recordings';
import ReportsExpOverview from 'views/admin/main/reports/indexExp';
import MedicalNotesOverview from 'views/admin/main/Medical_Notes';
import OverviewProfile from 'views/admin/main/profile';
import IndexTask from 'views/admin/main/others/messages/indexTask';
import OverviewVacations from 'views/admin/main/Vacations';
import OverviewPermissions from 'views/admin/main/Permissions';
import OverviewChangeSchedules from 'views/admin/main/ChangeSchedules';
import ReportsBonoOverview from 'views/admin/main/reports/indesBono';
import ReportsATZOverview from 'views/admin/main/reports/indexATZ';
import OverViewPicturesPanel from 'views/admin/main/pictures_panel';


const routes = [
  // --- Dashboards ---
  {
    name: 'Home',
    layout: '/admin',
    path: '/home',
    collapse: false,
    icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboards',
    collapse: false,
    icon: <Icon as={MdDashboard} width="30px" height="30px" color="inherit" />,
    component: <DailyOverview />,
  },
  {
    name: 'Schedules',
    layout: '/admin',
    path: '/schedules',
    collapse: false,
    icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
    component: <UsersSchedulesOverview />,
  },
  {
    name: 'Tickets',
    icon: <Icon as={IoTicketSharp} width="30px" height="30px" color="inherit" />,
    path: '/Ticket',
    collapse: true,
    items: [
      {
        name: 'Requests',
        layout: '/admin',
        path: '/ticket/chat',
        exact: false,
        component: <Default />,
      },
      {
        name: 'Completed',
        layout: '/admin',
        path: '/ticket/dashboard',
        exact: false,
        component: <IncidentsOverview />,
      },
      {
        name: 'Ongoing',
        layout: '/admin',
        path: '/ticketU/chat',
        exact: false,
        component: <DefaultUser />,
      },
      {
        name: 'My Completed',
        layout: '/admin',
        path: '/ticketU/finish',
        exact: false,
        component: <IncidentsAgentOverview />,
      },
      {
        name: 'Pending tasks',
        layout: '/admin',
        path: '/ticket/tasks',
        exact: false,
        component: <IndexTask />,
      },
    ],
  },
  {
    name: 'Reports',
    path: '/reports',
    icon: <Icon as={TbReportAnalytics} width="30px" height="30px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Billing',
        layout: '/admin',
        path: '/reports/overview',
        exact: false,
        component: <ReportsOverview />,
      },
      {
        name: 'Timesheet',
        layout: '/admin',
        path: '/reports/timesheet',
        exact: false,
        component: <ReportsTSOverview />,
      },
      {
        name: 'Payroll',
        layout: '/admin',
        path: '/reports/payroll',
        exact: false,
        component: <ReportsPROverview />,
      },
      {
        name: 'Bonos',
        layout: '/admin',
        path: '/reports/bonos',
        exact: false,
        component: <ReportsBonoOverview />,
      },
      {
        name: 'Attendance Zoom',
        layout: '/admin',
        path: '/reports/attendanceZoom',
        exact: false,
        component: <ReportsATZOverview />,
      },
      {
        name: 'Exports',
        layout: '/admin',
        path: '/reports/exports',
        exact: false,
        component: <ReportsExpOverview />,
      },
    ],
  },
  {
    name: 'Menu',
    path: '/main',
    icon: <Icon as={BsFillFilePersonFill} width="30px" height="30px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Users',
        path: '/users',
        layout: '/admin',
        component: <UsersOverview />,
      },
      {
        name: 'My schedule',
        path: '/my-schedule',
        layout: '/admin',
        component: <MyScheduleOverview />,
      },
      {
        name: 'Schedules Hrs',
        path: '/schedules-hrs',
        layout: '/admin',
        component: <SchedulesHrsOverview />,
      },
      {
        name: 'Clients',
        path: '/clients',
        component: <ClientsOverview />,
        layout: '/admin',
      },
      {
        name: 'User type',
        path: '/user-type',
        component: <TypeUserOverView />,
        layout: '/admin',
      },
      {
        name: 'Settings',
        path: '/settings',
        layout: '/admin',
        component: <SettingsOverview />,
      },
      {
        name: 'Recordings',
        path: '/recordings',
        layout: '/admin',
        component: <RecordingsOverview />,
      },
      {
        name: 'Medical Notes',
        path: '/MedicalNotes',
        layout: '/admin',
        component: <MedicalNotesOverview />,
      },
      {
        name: 'Report',
        layout: '/admin',
        path: '/reports-client',
        exact: false,
        component: <ReportsTSEOverview />,
      },
      {
        name: 'Profile',
        layout: '/admin',
        path: '/profile',
        exact: false,
        component: <OverviewProfile />,
      },
      {
        name: 'Vacations',
        layout: '/admin',
        path: '/vacations',
        exact: false,
        component: <OverviewVacations />,
      },
      {
        name: 'Permissions',
        layout: '/admin',
        path: '/permissions',
        exact: false,
        component: <OverviewPermissions />,
      },
      {
        name: 'Change Schedule',
        layout: '/admin',
        path: '/changeSchedule',
        exact: false,
        component: <OverviewChangeSchedules />,
      },
      {
        name: 'Picture profile panel',
        layout: '/admin',
        path: '/picturesProfile',
        exact: false,
        component: <OverViewPicturesPanel />,
      },
    ],
  },
];

export default routes;

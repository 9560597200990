import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
    Table, Thead, Tbody, Tr, Th, Td, Badge, Text, Flex, Box
} from '@chakra-ui/react'
import { FaRegClock, FaNoteSticky } from "react-icons/fa6";
import 'react-datepicker/dist/react-datepicker.css';
import ButtonsDashboard from './buttonsDashboard';

function DailyList({ schedules, getRowBackground, getColorSchemeForOption, getColorBadge, getColorSchemeForStatus, getColorSchemeForTypeDaily, handleInfo, userAuth, userAuthID, handleUpdate, handleNotes, handleCallOut, handleClockOut, handleUpdateZoom, handleRestoreZoom }) {

    return (
        <>
            <Table size={'sm'}>
                <Thead bg={"white"}>
                    <Tr bg={'white'}>
                        <Th textAlign={'center'}>Active</Th>
                        <Th textAlign={'center'}>Operator</Th>
                        <Th textAlign={'center'}>Weekly Bonus</Th>
                        <Th textAlign={'center'}>Client</Th>
                        <Th textAlign={'center'}>Position</Th>
                        <Th textAlign={'center'}>Work Type</Th>
                        <Th textAlign={'center'}>Time</Th>
                        <Th textAlign={'center'}>Notes</Th>
                        <Th textAlign={'center'}>Location</Th>
                        <Th textAlign={'center'}>Log</Th>
                        <Th textAlign={'center'}>Options</Th>
                    </Tr>
                </Thead>
                <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                    {schedules && schedules.length > 0 ? (
                        schedules.map((schedules, index) => (
                            <Tr key={schedules.id} bg={getRowBackground(schedules)} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                                <Td textAlign={'center'}>
                                    <Badge
                                        color={getColorSchemeForStatus(schedules.option)}
                                        bg={"transparent"}
                                        borderRadius={'5px'}
                                        width={'85px'}
                                        fontSize={'10px'}
                                    >
                                        {(() => {
                                            switch (schedules.option) {
                                                case 1:
                                                    return "---";
                                                case 2:
                                                    return "Clocked in";
                                                case 3:
                                                    return "Clocked in";
                                                case 4:
                                                    return "Clocked out";
                                                case 5:
                                                    return "Supervisor";
                                                case 6:
                                                    return "Call out";
                                                default:
                                                    return "---";
                                            }
                                        })()}
                                    </Badge>
                                </Td>
                                <Td textAlign={'center'} fontSize={'15px'}>
                                    <Flex direction='column'>
                                        <Text>
                                            {schedules.operator.split(' ')[0]} {schedules.operatorL.split(' ')[0]}
                                        </Text>
                                        <Text color={schedules.zoom === 1 ? '#b9ce67' : schedules.zoom === 0 ? '#fcd677' : '#b0b0b0'} mt={'2%'}>
                                            {schedules.zoom === null ? null :
                                                (schedules.zoom === 1 ? 'Zoom on time' :
                                                    schedules.zoom === 0 ? 'Zoom late' :
                                                        'Not needed')}
                                        </Text>
                                    </Flex>
                                </Td>
                                <Td textAlign={'center'} fontSize={'15px'}>
                                    <Text>
                                        {[22, 36, 43, 44].includes(schedules.type_user_id)
                                            && [1, 2, 3].includes(schedules.location_id)
                                            && [1, 2].includes(schedules.status_employee_id)
                                            ? schedules.bono : '---'}
                                    </Text>
                                </Td>
                                <Td textAlign={'center'}>
                                    <Badge color={"#424ea8"} bg={'white'} borderRadius={'5px'} width={'150px'} fontSize={'12px'} whiteSpace={'pre-wrap'}>
                                        {schedules.client}
                                    </Badge>
                                </Td>
                                <Td textAlign={'center'}>
                                    {schedules.position}
                                </Td>
                                <Td textAlign={'center'}>
                                    {([2, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15].includes(schedules.status)) ? (
                                        <>
                                            <Badge color={"white"} bg={getColorBadge(schedules.status)} width={'120px'} height={'22px'} fontSize='10px' ml={1} borderRadius={"5px"}>
                                                {(() => {
                                                    switch (schedules.status) {
                                                        case 1:
                                                            return "Normal";
                                                        case 2:
                                                            return "Off";
                                                        case 3:
                                                            return "Holiday";
                                                        case 4:
                                                            return "Vacation";
                                                        case 5:
                                                            return "Maternity Leave";
                                                        case 6:
                                                            return "Permission Request";
                                                        case 7:
                                                            return "Wedding Leave";
                                                        case 8:
                                                            return "Medical Leave";
                                                        case 9:
                                                            return "Paternity Leave";
                                                        case 10:
                                                            return "Bereavement Leave";
                                                        case 11:
                                                            return "Surecall O/C";
                                                        case 13:
                                                            return "No longer with us";
                                                        case 15:
                                                            return "Holiday Off";
                                                        default:
                                                            return "Normal";
                                                    }
                                                })()}
                                            </Badge>
                                        </>
                                    ) :
                                        <>

                                            <Badge color={getColorSchemeForTypeDaily(schedules.type_daily)} bg={"white"} boxShadow={"1px 2.5px 10px 7px #f2f2f2"} py={"5px"} width={'auto'} height={'22px'} fontSize='12px' borderRadius={"5px"}>
                                                {(() => {
                                                    switch (schedules.type_daily) {
                                                        case 1:
                                                            return "Regular shift";
                                                        case 2:
                                                            return "Regular training";
                                                        case 3:
                                                            return "Additional hours";
                                                        case 4:
                                                            return "Regular coverage";
                                                        case 5:
                                                            return "Regular shadowing"
                                                        case 6:
                                                            return "No shift"
                                                        case 7:
                                                            return "Additional training"
                                                        case 8:
                                                            return "Additional coverage"
                                                        case 9:
                                                            return "Additional shadowing"
                                                        default:
                                                            return "Regular shift";
                                                    }
                                                })()}
                                            </Badge>
                                        </>
                                    }
                                </Td>
                                <Td textAlign={'center'} color={'#7a86e6'} fontSize={'14px'} whiteSpace={'pre-wrap'} flexDirection={'row'}>
                                    {[2, 4, 5, 6, 7, 8, 9, 10, 13, 15].includes(schedules.status) ? '---' : schedules.time_start_12hr + ' ' + schedules.time_end_12hr}
                                </Td>
                                <Td textAlign={'center'} fontSize={'14px'} whiteSpace={'pre-wrap'}>
                                    <Flex justifyContent={'center'}>

                                        {(schedules.countNotes !== 0) ? (
                                            <>
                                                <Box>
                                                    <FaNoteSticky />
                                                </Box>
                                                <Text ml={'5%'}>
                                                    {schedules.countNotes}
                                                </Text>
                                            </>
                                        ) :
                                            <Box>
                                                <FaNoteSticky />
                                            </Box>
                                        }

                                    </Flex>
                                </Td>
                                <Td textAlign={'center'} color={'#cccccc'}>
                                    {(() => {
                                        switch (schedules.location_id) {
                                            case 1:
                                                return "MX";
                                            case 2:
                                                return "BZ";
                                            case 3:
                                                return "BZ";
                                            case 4:
                                                return "RD";
                                            case 5:
                                                return "COL";
                                            case 6:
                                                return "GTM";
                                            default:
                                                return "MX";
                                        }
                                    })()}
                                </Td>
                                <Td textAlign={'center'}>
                                    <Badge
                                        bg={"white"}
                                        color={getColorSchemeForOption(schedules.option)}
                                        borderRadius="5px"
                                    >
                                        <FaRegClock size={'25px'} />
                                    </Badge>
                                </Td>
                                <Td textAlign={'center'}>
                                    <ButtonsDashboard
                                        schedules={schedules}
                                        handleInfo={handleInfo}
                                        userAuth={userAuth}
                                        userAuthID={userAuthID}
                                        handleUpdate={handleUpdate}
                                        handleNotes={handleNotes}
                                        handleCallOut={handleCallOut}
                                        handleClockOut={handleClockOut}
                                        handleUpdateZoom={handleUpdateZoom}
                                        handleRestoreZoom={handleRestoreZoom}
                                    />
                                </Td>
                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Th colSpan={'11'} >
                                <Text
                                    color='#1c2147'
                                    fontSize={"25px"}
                                    textAlign={"center"}
                                    paddingTop={"15px"}
                                    paddingBottom={"15px"}
                                    bg={"#f2f2f2"}
                                    textTransform={'none'}
                                    fontFamily={'DM Sans'}
                                    letterSpacing="-1px"
                                >
                                    No data
                                </Text>
                            </Th>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </>
    );
}
export default DailyList; 
import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
    TableContainer, Flex, useToast
} from '@chakra-ui/react'
import ShowSchedule from '../items/Modals/modalshow';
import 'react-datepicker/dist/react-datepicker.css';
import ScheduleUpdateModal from '../items/Modals/modalupdate';
import ClockOutModal from '../items/Modals/modalClockOut';
import CallOutModal from '../items/Modals/modalCallOut';
import ModalNotes from '../items/Modals/modalNotes';
import DailyList from './tableDashboard';
import SearchDashboard from './search';

const getDayWithSuffix = (day) => {
    if (day >= 11 && day <= 13) {
        return day + 'th';
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
        case 1:
            return day + 'st';
        case 2:
            return day + 'nd';
        case 3:
            return day + 'rd';
        default:
            return day + 'th';
    }
};
function FunctionsDashboard({ sync, operatorSearch, setOperatorSearch, currentPage2, setCurrentPage2, currentPage3, setCurrentPage3, startDateFilter, setStartDateFilter }) {

    const [loading, setLoading] = useState(false);
    const [schedules, setSchedules] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [client, setClient] = useState([]);
    const [clientSearch, setClientSearch] = useState('');
    const [operator, setOperator] = useState([]);
    const [days, setDays] = useState([]);
    const [daySearch, setDaySearch] = useState('');
    const [optionSearch, setOptionSearch] = useState('');
    const [dailySearch, setDailySearch] = useState('');
    const [selectSchedule, setSelectSchedule] = useState(null);
    const [openShow, setOpenShow] = useState(false);
    const [locationSearch, setLocationSearch] = useState('');
    const [DateFilter, setDateFilter] = useState(new Date());
    const [resetKey, setResetKey] = useState(0);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openClockOut, setopenClockOut] = useState(false);
    const [openCallOut, setCallOut] = useState(false);
    const [openNotes, setNotes] = useState(false);
    const [usersClients, setUsersClients] = useState([]);
    const [timeSearch, setTimeSearch] = useState('');
    const [userAuth, setUserAuth] = useState([]);
    const [flashing, setFlashing] = useState(false);
    const [statusSearch, setStatusSearch] = useState('');
    const [userAuthID, setUserAuthID] = useState([]);
    const [typeU, setTypeU] = useState([]);
    const [typeUSearch, setTypeUSearch] = useState('');
    const [reOpenUpdate, setReOpenUpdate] = useState(false);
    const [toggleButton, setToggleButton] = useState(false);
    const [toggleButtonZoom, setToggleButtonZoom] = useState(false);
    const [zoomSearch, setZoomSearch] = useState('');
    const toast = useToast();


    const clearFilters = () => {
        setClientSearch(null);
        setLocationSearch('');
        setOperatorSearch(null);
        setDailySearch('');
        setOptionSearch('');
        setStatusSearch('');
        setTimeSearch('');
        setTypeUSearch('');
        setDaySearch('');
        setStartDateFilter('');
        setZoomSearch('');
        setCurrentPage(1);
        setCurrentPage2(1);
        setCurrentPage3(1);
        setToggleButton(false);
        setToggleButtonZoom(false);
        setResetKey(resetKey + 1);
        setDateFilter(new Date());
    }

    const optionsUsers = operator.map(operator => ({
        value: operator.id,
        label: `${operator.name} ${operator.last_name}`,
    }));

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const optionClients = client.map(client => ({
        value: client.id,
        label: client.name,
    }));

    const optionTypeU = typeU.map(typeU => ({
        value: typeU.id,
        label: typeU.name,
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '20px',
            fontSize: '16px',
            height: '40px',
            width: 'auto',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchData = async () => {
        const dateStartParam = startDateFilter ? `&date=${startDateFilter.toISOString()}` : '';
        try {
            const response = await axios.get(
                `/dashboard?page=${currentPage}`
                + `${clientSearch ? `&client=${encodeURIComponent(clientSearch)}` : ''}`
                + `${operatorSearch ? `&operator=${encodeURIComponent(operatorSearch)}` : ''}`
                + `${dailySearch ? `&daily=${dailySearch.trim()}` : ''}`
                + `${optionSearch ? `&option=${optionSearch.trim()}` : ''}`
                + `${statusSearch ? `&status=${statusSearch.trim()}` : ''}`
                + `${daySearch ? `&days=${daySearch.trim()}` : ''}`
                + `${locationSearch ? `&location=${locationSearch.trim()}` : ''}`
                + `${dateStartParam}`
                + `${timeSearch ? `&start_time=${timeSearch.trim()}` : ''}`
                + `${typeUSearch ? `&typeU=${encodeURIComponent(typeUSearch)}` : ''}`
                + `${zoomSearch ? `&zoom=${zoomSearch.trim()}` : ''}`
            );
            const responseData = response.data;
            setSchedules(responseData.data.data);
            setClient(responseData.client);
            setTypeU(responseData.typeU);
            setOperator(responseData.operator);
            setDays(responseData.day);
            setTotalPages(responseData.data.last_page);
            setTotal(responseData.data.total)
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleClientChange = (selectedOption) => {

        setClientSearch(selectedOption.value);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsersClients(userOptions);
                });
        }
    };

    const fetchDataAuth = async () => {
        try {
            const response = await axios.get("/user");
            const user = parseInt(response.data.user.type_user_id, 10);
            const id = parseInt(response.data.user.id, 10);
            setUserAuth(user);
            setUserAuthID(id);
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error);
        }
    };


    useEffect(() => {
        fetchData();
        fetchDataAuth();
        const intervalId = setInterval(() => {
            handleRefresh();
        }, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, [currentPage, clientSearch, operatorSearch, daySearch, optionSearch, statusSearch, locationSearch, resetKey, startDateFilter, timeSearch, dailySearch, typeUSearch, zoomSearch]);

    useEffect(() => {
        if (DateFilter) {
            const day = DateFilter.getDate();
            const month = DateFilter.toLocaleString('en-US', { month: 'long' });
            const year = DateFilter.getFullYear();
            const newFormattedDate = `${month} ${getDayWithSuffix(day)} ${year}`;
            setFormattedDate(newFormattedDate);
        }
    }, [DateFilter]);


    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setOperatorSearch(selectedOption.value);
                break;
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            case 'typeU':
                setTypeUSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const handleRefresh = () => {
        fetchData();
        sync();
    }


    const handleInfo = async (schedules) => {
        try {
            const response = await axios.get(`/schedule-weekly/show/${schedules.id}`);
            const { schedules: detail, user, userC, clients, breaks } = response.data;
            setSelectSchedule({ ...schedules, user, userC, clients, breaks });
            setOpenShow(true);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };
    const closeInfoModal = () => {
        setOpenShow(false);
    };
    const getColorSchemeForTypeDaily = (typeDaily) => {
        const colorMap = {
            1: '#7080fc',
            2: '#fcd677',
            3: '#8a6dce',
            4: '#dd67ae',
            5: '#fca066',
            6: '#fc7466',
            7: '#fcd677',
            8: '#dd67ae',
            9: '#fca066'
        };
        return colorMap[typeDaily] || '#7080fc';
    };
    const getColorSchemeForOption = (option) => {
        const colorMap = {
            1: '#a7a9ac',
            2: '#b9ce67',
            3: '#fcd677',
            4: '#988d77',
            5: '#6bc2ed',
            6: '#fc7466',
        };
        return colorMap[option] || '#a7a9ac';
    };

    const getColorBadge = (option) => {
        const colorMap = {
            1: '#8a6dce',
            13: '#fc7466'
        };
        return colorMap[option] || '#8a6dce';
    };

    const getColorSchemeForStatus = (option) => {
        const colorMap = {
            1: '#a7a9ac',
            2: '#b9ce67',
            3: '#b9ce67',
            4: '#988d77',
            5: '#6bc2ed',
            6: '#fc7466',
        };
        return colorMap[option] || '#a7a9ac';
    };

    const closeUpdateModal = () => {
        setOpenUpdate(false);
    };

    const handleUpdate = async (schedule) => {
        try {
            const response = await axios.get(`/schedule/edit/${schedule.id}`);
            const { schedule: detail, user, time, breaks, audits, incidents } = response.data;
            setSelectSchedule({ ...schedule, user, time, breaks, audits, incidents });
            setOpenUpdate(true);

        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const handleClockOut = async (schedules) => {
        try {
            const response = await axios.get(`/schedule/edit/${schedules.id}`);
            const { schedule: detail, user } = response.data;
            setSelectSchedule({ ...schedules, user });
            setSelectSchedule(schedules);
            setopenClockOut(true);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const closeClockOutModal = () => {
        setopenClockOut(false);
    };

    const handleCallOut = async (schedule) => {
        try {
            const response = await axios.get(`/schedule/edit/${schedule.id}`);
            const { schedule: detail, user } = response.data;
            setSelectSchedule({ ...schedule, user });
            setSelectSchedule(schedule);
            setCallOut(true);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const closeCallOutModal = () => {
        setCallOut(false);
    };


    const handleNotes = async (schedules) => {
        try {
            const response = await axios.get(`/schedule/edit/${schedules.id}`);
            const { schedule: detail, user } = response.data;
            setSelectSchedule({ ...schedules, user });
            setSelectSchedule(schedules);
            setNotes(true);

            if (openUpdate) {
                setOpenUpdate(false);
                setReOpenUpdate(true);
            }

        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const closeNotesModal = () => {
        setNotes(false);
        setReOpenUpdate(false);
    };

    const getRowBackground = (schedule) => {
        if (selectSchedule && selectSchedule.id === schedule.id) {
            if (flashing) {
                return '#fcd677';
            }
            return 'white';
        } else {
            return 'white';
        }
    };

    const toggleOptionSearch = () => {
        if (optionSearch) {
            setOptionSearch('');
            setToggleButton(false);
        } else {
            setOptionSearch('2,3');
            setToggleButton(true);
        }
    };

    const handleUpdateZoom = async (schedule) => {
        try {
            const { value: result } = await Swal.fire({
                title: 'The user on the zoom is:',
                text: "Choose an option:",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                input: 'radio',
                inputOptions: {
                    yes: 'On time',
                    no: 'Late',
                    noneed: 'Not needed'
                },
                inputValidator: (value) => {
                    if (!value) {
                        return 'You need to choose an option!';
                    }
                }
            });
    
            if (result === 'yes') {
                await axios.post(`/scheduleZoom/${schedule.id}`, { zoom: 1 });
                fetchData();
            } else if (result === 'no') {
                await axios.post(`/scheduleZoom/${schedule.id}`, { zoom: 0 });
                fetchData();
            } else if (result === 'noneed') {
                await axios.post(`/scheduleZoom/${schedule.id}`, { zoom: 2 });
                fetchData();
            }
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };
    
    
    

    const toggleZoomSearch = () => {
        if (optionSearch) {
            setOptionSearch('');
            setZoomSearch('');
            setToggleButtonZoom(false);
        } else {
            setOptionSearch('2,3');
            setZoomSearch('0,1');
            setToggleButtonZoom(true);
        }
    };

    const handleRestoreZoom = async (schedule) => {
        const confirmDelete = await Swal.fire({
            title: `Do you want to restore zoom value?`,
            text: `The zoom value will be restored`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C5282',
            cancelButtonColor: '#E53E3E',
            confirmButtonText: `Restored`
        }).then((result) => {
            return result.isConfirmed
        });
        if (confirmDelete) {
            setLoading(true);
            setSelectSchedule(schedule);
            try {
                const response = await axios.post(`/restoreZoom/${schedule.id}`);
                toast({
                    title: `Zoom value restored`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setFlashing(schedule);
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Restoring the zoom value",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <TableContainer className='dashboardfuncttable'>
            <Flex
                direction='column'
                w='100%'
            >
                <SearchDashboard
                    operatorSearch={operatorSearch}
                    startDateFilter={startDateFilter}
                    setStartDateFilter={setStartDateFilter}
                    clientSearch={clientSearch}
                    usersClients={usersClients}
                    optionsUsers={optionsUsers}
                    handleSelectChange={handleSelectChange}
                    setCurrentPage={setCurrentPage}
                    customStyles={customStyles}
                    resetKey={resetKey}
                    filterOptions={filterOptions}
                    optionClients={optionClients}
                    handleClientChange={handleClientChange}
                    dailySearch={dailySearch}
                    setDailySearch={setDailySearch}
                    optionSearch={optionSearch}
                    setOptionSearch={setOptionSearch}
                    statusSearch={statusSearch}
                    setStatusSearch={setStatusSearch}
                    locationSearch={locationSearch}
                    setLocationSearch={setLocationSearch}
                    timeSearch={timeSearch}
                    setTimeSearch={setTimeSearch}
                    setDateFilter={setDateFilter}
                    clearFilters={clearFilters}
                    total={total}
                    formattedDate={formattedDate}
                    handleRefresh={handleRefresh}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    optionTypeU={optionTypeU}
                    typeUSearch={typeUSearch}
                    toggleOptionSearch={toggleOptionSearch}
                    toggleButton={toggleButton}
                    zoomSearch={zoomSearch}
                    setZoomSearch={setZoomSearch}
                    toggleZoomSearch={toggleZoomSearch}
                    toggleButtonZoom={toggleButtonZoom}
                />
                <DailyList
                    schedules={schedules}
                    getRowBackground={getRowBackground}
                    getColorSchemeForOption={getColorSchemeForOption}
                    getColorSchemeForStatus={getColorSchemeForStatus}
                    getColorSchemeForTypeDaily={getColorSchemeForTypeDaily}
                    getColorBadge={getColorBadge}
                    handleInfo={handleInfo}
                    userAuth={userAuth}
                    userAuthID={userAuthID}
                    handleUpdate={handleUpdate}
                    handleNotes={handleNotes}
                    handleCallOut={handleCallOut}
                    handleClockOut={handleClockOut}
                    handleUpdateZoom={handleUpdateZoom}
                    handleRestoreZoom={handleRestoreZoom}
                />
            </Flex>

            {openShow && (
                <ShowSchedule
                    isOpen={openShow}
                    onClose={closeInfoModal}
                    schedule={selectSchedule}
                />
            )}
            {openUpdate && (
                <ScheduleUpdateModal
                    isOpen={openUpdate}
                    onClose={closeUpdateModal}
                    schedule={selectSchedule}
                    setFlashing={setFlashing}
                    user={operator}
                    data={handleUpdate}
                    client={client}
                    handleNotes={handleNotes}
                    dataUpdate={fetchData}
                    userAuth={userAuthID}
                />
            )}
            {openClockOut && (
                <ClockOutModal
                    isOpen={openClockOut}
                    onClose={closeClockOutModal}
                    schedule={selectSchedule}
                    data={fetchData}
                />
            )}
            {openCallOut && (
                <CallOutModal
                    isOpen={openCallOut}
                    onClose={closeCallOutModal}
                    schedule={selectSchedule}
                    data={fetchData}
                />
            )
            }
            {openNotes && (
                <ModalNotes
                    isOpen={openNotes}
                    onClose={closeNotesModal}
                    schedule={selectSchedule}
                    data={fetchData}
                    userAuth={userAuth}
                    reOpenUpdate={reOpenUpdate}
                    handleUpdate={handleUpdate}
                    setReOpenUpdate={setReOpenUpdate}
                />
            )
            }

        </TableContainer>
    );
}
export default FunctionsDashboard; 